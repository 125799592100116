:root {
  --font-body: "Montserrat", "sans-serif";
  --color-gold: #ffd700;
  --color-dark-grey: #464646;
  --color-light-grey: #f8f9fa;
  --color-light-grey-2: #eceeee;
  --color-grey: #a7a9aa;
  --color-black: #000;
  --color-white: #fff;
  --color: #0000002d;
}

html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html body a {
  text-decoration: none;
  color: var(--color-black);
}
html body .text-gold {
  color: var(--color-gold);
}
html body .text-black {
  color: var(--color-black);
}
html body .text-li-grey {
  color: var(--color-grey);
}
html body .text-white {
  color: var(--color-white);
}
html body .text-500 {
  font-size: 10vh;
}
html body .bg-black {
  background-color: var(--color-black);
}
html body .bg-grey {
  background-color: var(--color-light-grey);
}
html body .bg-grey-2 {
  background-color: var(--color-grey);
}
html body .bg-white {
  background-color: var(--color-white);
}
html body h1,
html body h2,
html body h3,
html body h4,
html body h5 {
  font-weight: bold;
}
html body .shadow {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1764705882);
  z-index: 3;
}
html body .left-menu {
  border-right: 2px solid var(--color-light-grey-2);
}

