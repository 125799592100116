:root{
    --font-body: 'Montserrat', 'sans-serif';
    --color-gold: #ffd700;
    --color-dark-grey: #464646;
    --color-light-grey: #f8f9fa;
    --color-light-grey-2: #eceeee;
    --color-grey: #a7a9aa;
    --color-black: #000;
    --color-white: #fff;
    --color: #0000002d;
}

html {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

    body {
        a {

            text-decoration: none;
            color:var(--color-black);
        }

        .text-gold {
            color: var(--color-gold);
        }

        .text-black {
            color: var(--color-black);
        }

        .text-li-grey {
            color: var(--color-grey);
        }

        .text-white {
            color: var(--color-white);
        }

        .text-500 {
            font-size: 10vh;
        }

        .bg-black {
            background-color: var(--color-black);
        }

        .bg-grey {
            background-color: var(--color-light-grey);
        }

        .bg-grey-2 {
            background-color: var(--color-grey);
        }

        .bg-white {
            background-color: var(--color-white);
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            font-weight: bold;
        }

        .shadow {
            box-shadow: 3px 3px 7px #0000002d;
            z-index: 3;
        }

        .left-menu {
            border-right: 2px solid var(--color-light-grey-2);

        }

    }
}